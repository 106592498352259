import React from "react";

const PhotoCollage: React.FC = () => {
  const images = [
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/IMG_6948.JPG",
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/IMG_2203.JPG",
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/IMG_0387.jpeg",
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/IMG_4458.jpeg",
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/IMG_2707.jpeg",
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/IMG_2656.jpeg",
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/IMG_1463.JPG",
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/IMG_1291.jpeg",
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/IMG_2600.JPG",
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/IMG_7223.jpg",
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/IMG_8648.jpg",
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/A1AA90E8-6036-4F68-948C-16C7DCEC01DA.jpeg",
  ];

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      {Array.from({ length: 4 }).map((_, colIndex) => (
        <div key={colIndex} className="grid gap-4">
          {images.slice(colIndex * 3, colIndex * 3 + 3).map((src, imgIndex) => (
            <div key={imgIndex}>
              <img className="h-auto max-w-full rounded-lg" src={src} alt="" />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default PhotoCollage;
