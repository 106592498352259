import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store";
import { editMember, getMember } from "../../actions/members";
import { CREATE_MEMBER_FORM, MEMBER } from "../../@types";
import { getChapter } from "../../actions/chapters";

interface EditMemberFormProps {
  memberId: number | string;
  onClose: () => void;
}

const EditMemberForm: React.FC<EditMemberFormProps> = ({
  memberId,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { members } = useSelector((state: RootState) => state.members);
  // const [formData, setFormData] = useState<MEMBER | null>(null);
  const [formData, setFormData] = useState<
    CREATE_MEMBER_FORM & { id: number | string }
  >({
    id: memberId,
    full_name: "",
    email: "",
    date_of_birth: "",
    chapter: "",
    member_class: "",
    member_status: "",
    national_board_active_member: false,
    omega_status: false,
    og_founding_father: false,
  });

  useEffect(() => {
    dispatch(getMember(memberId));
  }, [dispatch, memberId]);

  useEffect(() => {
    const currentMember = members.find((member) => member.id === memberId);
    if (currentMember) {
      setFormData({
        id: memberId,
        full_name: currentMember.full_name || "",
        email: currentMember.email || "",
        date_of_birth: currentMember.date_of_birth || "",
        chapter: currentMember.chapter || "",
        member_class: currentMember.member_class || "",
        member_status: currentMember.member_class || "",
        national_board_active_member:
          currentMember.national_board_active_member || false,
        omega_status: currentMember.omega_status || false,
        og_founding_father: currentMember.og_founding_father || false,
      });
    }
  }, [members, memberId]);

  // useEffect(() => {
  //   const fetchMember = async () => {
  //     const member = await dispatch(getMember(memberId)).unwrap();
  //     setFormData(member);
  //   };

  //   fetchMember();
  // }, [dispatch, memberId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) =>
      prevData
        ? {
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
          }
        : prevData
    );
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData) {
      dispatch(editMember(formData));
      onClose();
    }
  };

  if (!formData) return null;

  return (
    <form onSubmit={handleSubmit} className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-xl font-semibold mb-4">Edit Member</h2>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <div className="flex flex-col">
          <label className="mb-1 font-medium">Member Name</label>
          <input
            type="text"
            name="full_name"
            value={formData.full_name}
            onChange={handleChange}
            className="px-4 py-2 border rounded-md"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-1 font-medium">Email Address</label>
          <input
            type="text"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="px-4 py-2 border rounded-md"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-1 font-medium">Date of Birth</label>
          <input
            type="date"
            name="date_of_birth"
            value={formData.date_of_birth.toString().split("T")[0]}
            onChange={handleChange}
            className="px-4 py-2 border rounded-md"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-1 font-medium">Chapter</label>
          <input
            type="text"
            name="chapter"
            value={formData.chapter}
            onChange={handleChange}
            className="px-4 py-2 border rounded-md"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-1 font-medium">Class</label>
          <input
            type="text"
            name="member_class"
            value={formData.member_class}
            onChange={handleChange}
            className="px-4 py-2 border rounded-md"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-1 font-medium">Member Status</label>
          <input
            type="text"
            name="member_status"
            value={formData.member_status}
            onChange={handleChange}
            className="px-4 py-2 border rounded-md"
          />
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            name="national_board_active_member"
            checked={formData.national_board_active_member}
            onChange={handleChange}
            className="mr-2"
          />
          <label className="font-medium">National Board Active Member</label>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            name="omega_status"
            checked={formData.omega_status}
            onChange={handleChange}
            className="mr-2"
          />
          <label className="font-medium">Omega Status</label>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            name="og_founding_father"
            checked={formData.og_founding_father}
            onChange={handleChange}
            className="mr-2"
          />
          <label className="font-medium">OG Founding Father</label>
        </div>
      </div>
      <div className="flex justify-end mt-6">
        <button
          type="submit"
          className="px-4 py-2 bg-blue-600 text-white rounded-md mr-2 hover:bg-blue-700"
        >
          Save Changes
        </button>
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default EditMemberForm;
