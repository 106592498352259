import React, { useEffect, useRef } from "react";

import placeholderImg from "../../assets/Images/noImgAvail.png";
import spacexImg from "../../assets/Images/spacexlogo.png";
import univisionImg from "../../assets/Images/univisonlogo.png";
import dhlImg from "../../assets/Images/dhllogo.png";
import teslaImg from "../../assets/Images/teslalogo.png";
import greendotImg from "../../assets/Images/greendotlogo.png";
import metaImg from "../../assets/Images/metalogo.png";

const CompanyCarousel: React.FC = () => {
  const carouselRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const totalDuration = 15000; // Total duration for a full cycle in milliseconds
    const transitionDuration = 5000; // Duration of each transition in milliseconds
    let transitionIndex = 0;

    const rotateImages = () => {
      transitionIndex = (transitionIndex + 1) % 3;
      const newTranslateX = -transitionIndex * 100;

      if (carouselRef.current) {
        carouselRef.current.style.transition = "transform 1s ease-in-out";
        carouselRef.current.style.transform = `translateX(${newTranslateX}%)`;
      }

      // Restart the rotation after the last transition
      setTimeout(rotateImages, transitionDuration);
    };

    // Start the rotation
    const timeoutId = setTimeout(rotateImages, transitionDuration);

    // Clean up the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div>
      <div className="flex flex-col justify-center items-center">
        <h1 className="font-bold font-oswald text-2xl">
          Endless Opportunities
        </h1>
        <p className="text-md italic">
          Checkout where brothers end up after graduating
        </p>
      </div>
      <div className="overflow-hidden max-w-4xl mx-auto">
        <div className="flex" ref={carouselRef}>
          <div className="flex w-full flex-shrink-0 items-center content-center">
            <img src={spacexImg} alt="SpaceX" className="w-1/4 h-60" />
            <img src={univisionImg} alt="Univision" className="w-1/4" />
            <img src={dhlImg} alt="DHL" className="w-1/4" />
            <img src={teslaImg} alt="Tesla" className="w-1/4 h-12" />
          </div>
          <div className="flex w-full flex-shrink-0 items-center content-center">
            <img src={greendotImg} alt="Green Dot" className="w-1/4" />
            <img src={metaImg} alt="Placeholder" className="w-1/4 h-40" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyCarousel;
