import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import {
  getCarouselPosts,
  deleteCarouselPost,
} from "../../actions/carouselposts";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditEventPostForm from "../EditEventPostForm";
import EditCarouselPostForm from "../EditCarouselPostForm";

const StyledTableCellHeader = styled(TableCell)(() => ({
  backgroundColor: "#F5F5F5",
  fontFamily: "Neue Haas Grotesk",
  fontWeight: "600",
  textAlign: "center",
}));

const StyledTableCell = styled(TableCell)(() => ({
  border: "none",
  fontWeight: "500",
  textAlign: "center",
  fontFamily: "Neue Haas Grotesk",
}));

const formatDate = (date: string | Date): string => {
  if (!date) return "N/A";
  const d = typeof date === "string" ? new Date(date) : date;
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  const year = d.getFullYear();
  return `${month}-${day}-${year}`;
};

const AdminCarouselPostTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { carouselPosts, status, error } = useAppSelector(
    (state) => state.carouselposts
  );
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editCarouselPostId, setEditCarouselPostId] = useState<
    number | string | null
  >(null);

  useEffect(() => {
    dispatch(getCarouselPosts());
  }, [dispatch]);

  const handleDelete = (id: number | string) => {
    if (window.confirm("Are you sure you want to delete this?")) {
      dispatch(deleteCarouselPost(id));
    }
  };

  const handleEditOpen = (id: number | string) => {
    setEditCarouselPostId(id);
    setEditDialogOpen(true);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setEditCarouselPostId(null);
  };

  if (status === "pending") {
    return <div>Loading...</div>;
  }

  if (status === "failed") {
    const errorMessage = error?.detail ? error.detail : error;
    return <div>Error: {errorMessage}</div>;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table arial-label="carousel-post-table">
          <TableHead>
            <TableRow>
              <StyledTableCellHeader>Event Title</StyledTableCellHeader>
              <StyledTableCellHeader>Start Date</StyledTableCellHeader>
              <StyledTableCellHeader>End Date</StyledTableCellHeader>
              <StyledTableCellHeader>Event Body</StyledTableCellHeader>
              <StyledTableCellHeader>Event Image</StyledTableCellHeader>
              <StyledTableCellHeader>Display Event</StyledTableCellHeader>
              <StyledTableCellHeader>Actions</StyledTableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {carouselPosts &&
              carouselPosts.map((carouselpost: any) =>
                carouselpost && carouselpost.carousel_post_title ? (
                  <TableRow key={carouselpost?.id}>
                    <StyledTableCell>
                      {carouselpost?.carousel_post_title || "N/A"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {carouselpost?.carousel_post_start_date
                        ? formatDate(carouselpost.carousel_post_start_date)
                        : "N/A"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {carouselpost?.carousel_post_end_date
                        ? formatDate(carouselpost.carousel_post_end_date)
                        : "N/A"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {carouselpost?.carousel_post_body || "N/A"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {carouselpost?.carousel_post_image || "N/A"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {carouselpost.carousel_postt_to_display ? "Yes" : "No"}
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        onClick={() => handleEditOpen(carouselpost.id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(carouselpost.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                ) : (
                  <TableRow key={carouselpost?.id || Math.random()}>
                    <StyledTableCell colSpan={4}>Invalid Data</StyledTableCell>
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={editDialogOpen} onClose={handleEditClose}>
        <DialogTitle>Edit National Board Member</DialogTitle>
        <DialogContent>
          {editCarouselPostId && (
            <EditCarouselPostForm
              carouselPostId={editCarouselPostId}
              onClose={handleEditClose}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminCarouselPostTable;
